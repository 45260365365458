import React from 'react';
import styles from './ArticleCard.module.scss';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import Button, { ButtonVariant } from '../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import cx from 'classnames';
import arrowRightIcon from '../../../assets/icons/right.svg';

type Props = {
  vertical?: boolean;
  thumbnail: string;
  title: string;
  excerpt: string;
};

const ArticleCard = ({
  vertical = false,
  thumbnail,
  title,
  excerpt,
}: Props) => {
  return (
    <Box className={cx(styles.articleCard, { [styles.vertical]: vertical })}>
      <img src={thumbnail} className={styles.thumbnail} />

      <Box className={styles.content}>
        <Typography className={styles.title}>{title}</Typography>
        <Typography className={styles.excerpt}>{excerpt}</Typography>

        <Button variant={ButtonVariant.contained} className={styles.button}>
          Read more
          <ReactSVG src={arrowRightIcon} />
        </Button>
      </Box>
    </Box>
  );
};

export default ArticleCard;
