import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ItemsDropdown.module.scss';
import downIcon from '../../../assets/icons/down.svg';
import cx from 'classnames';

export enum ItemsDropdownVariant {
  'dark' = 'dark',
  'default' = 'default',
}

export type ItemsDropdownProps = {
  title: string;
  icon: string | React.ReactNode;
  variant?: ItemsDropdownVariant;
};

// TODO add real info
export const ItemsDropdown = ({
  title,
  icon,
  variant = ItemsDropdownVariant.dark,
}: ItemsDropdownProps) => {
  return (
    <Box className={cx(styles.itemsDropdownContainer, styles[variant])}>
      <Box className={styles.selectedItem}>
        {typeof icon === 'string' ? (
          <img className={styles.icon} src={icon} />
        ) : (
          icon
        )}
        <Typography className={styles.title}>{title}</Typography>
      </Box>
      <img src={downIcon} />
    </Box>
  );
};

export default ItemsDropdown;
