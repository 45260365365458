import React from 'react';
import styles from './MyGarageMenu.module.scss';
import { Box, Menu, Radio, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import Button, { ButtonVariant } from '../../common/Button/Button';
import infoIcon from '../../../assets/icons/info.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import chevronRightIcon from '../../../assets/icons/chevron-right.svg';
import refreshIcon from '../../../assets/icons/refresh.svg';
import TextInput from '../../common/Input/TextInput/TextInput';
import saveIcon from '../../../assets/icons/save.svg';
import { useWindowWidth } from '@react-hook/window-size';
import Modal from '../../common/Modal/Modal';

type Props = {
  anchorEl?: HTMLElement | null;
  isOpen: boolean;
  onClose: () => void;
};

const fakeCars = [
  {
    id: 1,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 2,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
  {
    id: 3,
    name: 'Honda Civic XI (FE,FL) XI Sedanas',
    description:
      '1.5 Turbo (FE1) (95 kW / 129 AG) (08.2021 - until the end of dawn and more)',
  },
];

const MOBILE_BREAKPOINT = 900;

const MyGarageMenu = ({ isOpen, onClose, anchorEl }: Props) => {
  const width = useWindowWidth();

  const content = (
    <>
      <Box className={styles.infoContainer}>
        <Typography className={styles.title}>Mano Garažas</Typography>

        <Box className={styles.infoPopout}>
          <ReactSVG src={infoIcon} />
          <Typography className={styles.infoText}>
            Įrašykite transporto priemonės VIN numerį, kad galėtumėte tiksliau
            atlikti paiešką
          </Typography>
        </Box>
      </Box>

      <Box className={styles.carListContainer}>
        {fakeCars.map((car) => (
          <Box key={car.id} className={styles.car}>
            <Radio />
            <Box className={styles.carInfo}>
              <Typography className={styles.carName}>{car.name}</Typography>
              <Typography className={styles.carDescription}>
                {car.description}
              </Typography>
            </Box>
            <Box className={styles.actionsContainer}>
              <ReactSVG className={styles.deleteBtn} src={deleteIcon} />
              <ReactSVG className={styles.goBtn} src={chevronRightIcon} />
            </Box>
          </Box>
        ))}

        <Box className={styles.vinContainer}>
          <TextInput className={styles.vinInput} label="Įrašykite VIN numerį" />
          <Button variant={ButtonVariant.secondary}>
            <ReactSVG src={refreshIcon} />
          </Button>
          <Button>
            <ReactSVG src={saveIcon} />
          </Button>
        </Box>

        <Button wrapperClassName={styles.btn}>Button</Button>
      </Box>
    </>
  );

  if (width < MOBILE_BREAKPOINT) {
    return (
      <Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
        {content}
      </Modal>
    );
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      className={styles.menu}
    >
      {content}
    </Menu>
  );
};

export default MyGarageMenu;
