import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './SocialCard.module.scss';

export type SocialCardProps = {
  image: string;
  name: string;
};

export const SocialCard = ({ image, name }: SocialCardProps) => {
  return (
    <Box className={styles.socialCardContainer}>
      <img src={image} />
      <Typography className={styles.name}>{name}</Typography>
    </Box>
  );
};

export default SocialCard;
