import React, { useState } from 'react';
import styles from './LoginModal.module.scss';
import { Box, Typography } from '@mui/material';
import Modal from '../../common/Modal/Modal';
import TextInput from '../../common/Input/TextInput/TextInput';
import PasswordInput from '../../common/Input/PasswordInput/PasswordInput';
import CheckboxInput from '../../common/Input/CheckboxInput/CheckboxInput';
import Button from '../../common/Button/Button';
import cx from 'classnames';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const LoginModal = ({ isOpen, onClose }: Props) => {
  const [isRegister, setIsRegister] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <form className={styles.loginForm}>
        <Typography className={styles.title}>
          {isRegister ? 'Create new account' : 'Login'}
        </Typography>

        <Box className={styles.inputContainer}>
          {isRegister && (
            <>
              <TextInput name="name" label="Name" />
              <TextInput name="surname" label="Surname" />
            </>
          )}
          <TextInput name="email" required label="Your e-mail" />
          <PasswordInput name="password" label="Password" />
        </Box>
        <Box
          className={cx(styles.checkBoxContainer, {
            [styles.register]: isRegister,
          })}
        >
          {isRegister ? (
            <>
              <Typography className={styles.actionText}>
                Please see our{' '}
                <span className={styles.highlighted}>Privacy Policy</span>.
              </Typography>
              <CheckboxInput
                name="notificationConfirm"
                label={
                  <>
                    Yes, I agree to receive the PartEvo newsletter with
                    personalized offers, special discounts, requests to
                    participate in...{' '}
                    <span className={styles.highlighted}>Daugiau</span>
                  </>
                }
              />
            </>
          ) : (
            <>
              <CheckboxInput name="memorize" label="Memorize" />
              <Typography className={cx(styles.actionText, styles.highlighted)}>
                Forgot your password?
              </Typography>
            </>
          )}
        </Box>

        <Button wrapperClassName={styles.loginBtn}>
          {isRegister ? 'Register' : 'Login'}
        </Button>

        {!isRegister && (
          <Typography className={styles.description}>
            If you don&apos;t have an PartEvo account,{' '}
            <span
              className={styles.highlighted}
              onClick={() => setIsRegister(true)}
            >
              register
            </span>{' '}
            now.
          </Typography>
        )}
      </form>
    </Modal>
  );
};

export default LoginModal;
