import React from 'react';
import { Box } from '@mui/material';
import styles from './FooterColumn.module.scss';
import FooterItem, { FooterItemProps } from './FooterItem/FooterItem';

export type FooterColumnProps = {
  title: string;
  items: FooterItemProps[];
};

export const FooterColumn = ({ title, items }: FooterColumnProps) => {
  return (
    <Box className={styles.footerColumnContainer}>
      <Box className={styles.title}>{title}</Box>
      <Box className={styles.itemsContainer}>
        {items.map((item, index) => (
          <FooterItem
            key={`item-${index}`}
            label={item.label}
            link={item.link}
            icon={item.icon}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FooterColumn;
