import React from 'react';
import PublicLayout from './PublicLayout/PublicLayout';
import BasketLayout from './BasketLayout/BasketLayout';
import ProfileLayout from './ProfileLayout/ProfileLayout';

export enum PageLayoutType {
  'public' = 'public',
  'basket' = 'basket',
  'profile' = 'profile',
}

type LayoutFactoryProps = {
  variant: PageLayoutType;
};

export const LayoutFactory = ({ variant }: LayoutFactoryProps) => {
  switch (variant) {
    case PageLayoutType.public:
      return <PublicLayout />;
    case PageLayoutType.basket:
      return <BasketLayout />;
    case PageLayoutType.profile:
      return <ProfileLayout />;
    default:
      return null;
  }
};
