import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './ManufacturerCard.module.scss';

export type ManufacturerCardProps = {
  title: string;
};

export const ManufacturerCard = ({ title }: ManufacturerCardProps) => {
  return (
    <Box className={styles.manufacturerCardContainer}>
      <Typography className={styles.title}>{title}</Typography>
    </Box>
  );
};

export default ManufacturerCard;
