import React from 'react';
import { ReactSVG } from 'react-svg';
import hamburgerMenuIcon from '../../../../../assets/icons/hamburger-menu.svg';
import styles from './MobileDrawer.module.scss';
import { Drawer } from '@mui/material';

const MobileDrawer = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ReactSVG
        src={hamburgerMenuIcon}
        className={styles.hamburgerIcon}
        onClick={() => setOpen(true)}
      />
      <Drawer
        className={styles.drawer}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={styles.drawerContent}>Working on it...</div>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
