import React from 'react';
import styles from './AnnouncementBar.module.scss';
import { Box, Typography } from '@mui/material';

const AnnouncementBar = () => {
  return (
    <Box className={styles.announcementBar}>
      <Typography className={styles.announcementText}>
        Something if needed
      </Typography>
    </Box>
  );
};

export default AnnouncementBar;
