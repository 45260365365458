export const routes = {
  homepage: '/',
  catalog: '/catalog',
  products: '/products',
  product: '/product',
  checkout: '/checkout',
  checkoutLogin: '/checkout/login',
  profile: {
    address: '/profile/address',
    bankDetails: '/profile/bank-details',
    favorites: '/profile/favorites',
    orders: '/profile/orders',
    settings: '/profile/settings',
    vehicles: '/profile/vehicles',
  },
  articles: {
    index: '/articles',
    categories: '/articles/categories',
    category: '/articles/categories/:slug',
    article: '/articles/:slug',
  },
  auth: {
    passwordReset: '/',
  },
};
