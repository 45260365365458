import React, { ReactNode } from 'react';
import styles from './Section.module.scss';
import { Box, Typography } from '@mui/material';
import cx from 'classnames';

export type SectionProps = {
  title?: string;
  className?: string;
  accent?: boolean;
  titleClassName?: string;
};

type Props = {
  children: ReactNode;
} & SectionProps;

export const Section = ({
  title,
  children,
  className,
  titleClassName,
  accent = false,
}: Props) => {
  return (
    <Box className={cx(styles.sectionContainer, { [styles.accent]: accent })}>
      <Box className={styles.sectionInner}>
        {title && (
          <Typography className={cx(styles.title, titleClassName)}>
            {title}
          </Typography>
        )}
        <Box className={cx(styles.contentContainer, className)}>{children}</Box>
      </Box>
    </Box>
  );
};

export default Section;
