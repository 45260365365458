import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import TopNavigation from '../PublicLayout/TopNavigation/TopNavigation';
import CTA from '../../components/CTA/CTA';
import Community from '../../components/Community/Community';
import FeaturedBlogs from '../../components/FeaturedBlogs/FeaturedBlogs';
import Subscribe from '../../components/Subscribe/Subscribe';
import { Box } from '@mui/material';
import styles from './ProfileLayout.module.scss';
import ProfileSidebar from './ProfileSidebar/ProfileSidebar';
import AnnouncementBar from '../../components/AnnouncementBar/AnnouncementBar';

export const ProfileLayout = () => {
  return (
    <>
      <AnnouncementBar />
      <TopNavigation />
      <Box className={styles.profileContainer}>
        <Box className={styles.innerContainer}>
          <ProfileSidebar />
          <Outlet />
        </Box>
      </Box>
      <CTA />
      <Community />
      <FeaturedBlogs />
      <Subscribe />
      <Footer />
    </>
  );
};

export default ProfileLayout;
