export enum LocalStorageKey {
  token = 'token',
  language = 'language',
  version = 'version',
  needsRestart = 'needsRestart',
  errorRepeat = 'errorRepeat',
}

export const setLocalStorageValue = <T>(key: LocalStorageKey, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageValue = <T>(key: LocalStorageKey): T | null => {
  const value = localStorage.getItem(key);

  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

export const clearLocalStorageValue = (key: LocalStorageKey) => {
  localStorage.removeItem(key);
};
