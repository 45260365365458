import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './CTA.module.scss';
import ctaImage from '../../../assets/cta-image.webp';
import ctaPlaceholder from '../../../assets/cta-placeholder.svg';
import ctaPlaceholderMobile from '../../../assets/cta-placeholder-mobile.svg';
import Button, { ButtonVariant } from '../../common/Button/Button';
import cx from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';

const MOBILE_BREAKPOINT = 900;

export const CTA = () => {
  const width = useWindowWidth();

  return (
    <Box className={styles.ctaContainer}>
      <Box className={styles.innerContainer}>
        <img src={ctaImage} className={styles.ctaImage} />
        <Box className={styles.placeholderContainer}>
          <img
            src={
              width > MOBILE_BREAKPOINT ? ctaPlaceholder : ctaPlaceholderMobile
            }
            className={styles.ctaPlaceholder}
          />
          <Box className={styles.textContainer}>
            <Box className={styles.lineContainer}>
              <Typography className={styles.firstLine}>Super deal</Typography>
              <Typography className={cx(styles.firstLine, styles.buyNow)}>
                Buy now
              </Typography>
            </Box>
            <Box className={styles.lineContainer}>
              <Typography className={styles.secondLine}>Pay</Typography>
              <Typography className={cx(styles.secondLine, styles.cta)}>
                later CTA...
              </Typography>
              <Button
                variant={ButtonVariant.secondary}
                className={styles.buttonCta}
              >
                Button
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CTA;
