import React, { useMemo } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import styles from './Footer.module.scss';
import FooterColumn, { FooterColumnProps } from './FooterColumn/FooterColumn';
import logo from '../../../assets/logo.svg';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import phoneIcon from '../../../assets/icons/phone.svg';
import mailIcon from '../../../assets/icons/mail.svg';
import ltIcon from '../../../assets/icons/lt-flag.svg';
import ukIcon from '../../../assets/icons/uk-flag.svg';
import ainisIcon from '../../../assets/icons/ainis.svg';

export const Footer = () => {
  const columns: FooterColumnProps[][] = useMemo(() => {
    return [
      [
        {
          title: 'Top Products',
          items: [
            { label: 'Product one', link: '' },
            { label: 'Product two', link: '' },
            { label: 'Product three', link: '' },
            { label: 'Product four', link: '' },
            { label: 'Product five', link: '' },
            { label: 'Product six', link: '' },
            { label: 'Product seven', link: '' },
            { label: 'Product eight', link: '' },
            { label: 'Product nine', link: '' },
            { label: 'Product ten', link: '' },
          ],
        },
      ],
      [
        {
          title: 'Categories',
          items: [
            { label: 'Tires', link: '' },
            { label: 'Company information', link: '' },
            { label: 'Brakes', link: '' },
            { label: 'Damping', link: '' },
            { label: 'Suspension', link: '' },
            { label: 'Filters', link: '' },
            { label: 'Engine', link: '' },
            { label: 'Body', link: '' },
            { label: 'Clutch', link: '' },
            { label: 'Exhaust', link: '' },
            { label: 'Belts, chains, rollers', link: '' },
            { label: 'Oils and fluids', link: '' },
            { label: 'Other categories', link: '' },
          ],
        },
      ],
      [
        {
          title: 'About PartEvo',
          items: [
            { label: 'About us', link: '' },
            { label: 'Company information', link: '' },
            { label: 'Bonus program', link: '' },
            { label: 'Press', link: '' },
            { label: 'Something else', link: '' },
          ],
        },
        {
          title: 'Payment methods',
          items: [
            { label: 'About us', link: '' },
            { label: 'Company information', link: '' },
            { label: 'Bonus program', link: '' },
            { label: 'Press', link: '' },
            { label: 'Something else', link: '' },
          ],
        },
      ],
      [
        {
          title: 'Customer service',
          items: [
            { label: 'Payment', link: '' },
            { label: 'Delivery', link: '' },
            { label: 'Returns & refunds', link: '' },
            { label: 'Help center', link: '' },
            { label: 'Core exchange', link: '' },
          ],
        },
        {
          title: 'Delivery methods',
          items: [
            { label: 'About us', link: '' },
            { label: 'Company information', link: '' },
            { label: 'Bonus program', link: '' },
            { label: 'Press', link: '' },
            { label: 'Something else', link: '' },
          ],
        },
      ],
      [
        {
          title: 'Help & support',
          items: [
            { label: 'Blog', link: '' },
            { label: 'Tutorials', link: '' },
            { label: 'Privacy policy', link: '' },
            { label: 'Cookie settings', link: '' },
            { label: 'Code of confuct', link: '' },
          ],
        },
        {
          title: 'Follow us',
          items: [
            { label: 'Blog', link: '' },
            { label: 'Tutorials', link: '' },
            { label: 'Privacy policy', link: '' },
            { label: 'Cookie settings', link: '' },
            { label: 'Code of confuct', link: '' },
          ],
        },
      ],
    ];
  }, []);

  const companyInfoColumns: FooterColumnProps[] = useMemo(() => {
    return [
      {
        title: 'Contacts',
        items: [
          {
            label: '+370 XXX XXX XXX',
            link: '',
            icon: phoneIcon,
          },
          {
            label: 'e-mail@mail.com',
            link: '',
            icon: mailIcon,
          },
        ],
      },
      {
        title: 'Working time',
        items: [
          {
            label: 'I-V 8:00 am - 6:00pm',
            link: '',
          },
          {
            label: 'VI -VII closed',
            link: '',
          },
        ],
      },
      {
        title: 'Languages',
        items: [
          {
            label: 'English',
            link: '',
            icon: ukIcon,
          },
          {
            label: 'Lietuvių',
            link: '',
            icon: ltIcon,
          },
        ],
      },
    ];
  }, []);

  return (
    <>
      <Box className={styles.footerContainer}>
        <Box className={styles.innerContainer}>
          <Box className={styles.columnsContainer}>
            {columns.map((columnItems, i) => (
              <Box key={`column-item-${i}`} className={styles.columnContainer}>
                {columnItems.map((column, j) => (
                  <FooterColumn
                    key={`column-${j}`}
                    title={column.title}
                    items={column.items}
                  />
                ))}
              </Box>
            ))}
          </Box>
          <Divider className={styles.divider} />
          <Box className={styles.companyInfoContainer}>
            <Box className={styles.companyInfo}>
              <Link to={routes.homepage}>
                <img src={logo} className={styles.logo} />
              </Link>
              <Typography className={styles.companyShortInfo}>
                Short info about company Maecenas pharetra, nunc venenatis
                tristique tincidunt, sapien turpis ullamcorper tellus, eget
                venenatis ante odio vitae dui.
              </Typography>
            </Box>
            <Box className={styles.companyColumns}>
              {companyInfoColumns.map((column, index) => (
                <FooterColumn
                  key={`column-${index}`}
                  title={column.title}
                  items={column.items}
                />
              ))}
            </Box>
          </Box>
          <Box className={styles.copyrightContainer}>
            <Typography className={styles.copyright}>
              All right recerved © 2024 partevo.com
            </Typography>
            <Typography className={styles.copyright}>
              Made by Ainis.Space <img src={ainisIcon} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
