import React from 'react';
import { Box } from '@mui/material';

type HelperTextProps = {
  errors?: string[];
};

export const HelperText = ({ errors }: HelperTextProps) => {
  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <Box>
      {errors.map((error, index) => (
        <Box key={`error-index-${index}`}>{error}</Box>
      ))}
    </Box>
  );
};

export default HelperText;
