import React, { useState } from 'react';
import {
  InputAdornment,
  TextField,
  IconButton as MuiIconButton,
  Box,
} from '@mui/material';
import { InputValueType } from '../../../hooks/useForm/useForm';
import HelperText from '../../HelperText/HelperText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './PasswordInput.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';

export type PasswordInputProps = {
  label?: string;
  name?: string;
  value?: InputValueType;
  errors?: string[];
  onChange?: (name: string, value: string) => void;
  onBlur?: Function;
  autoFocus?: boolean;
  className?: string;
  includePasswordReset?: boolean;
};

export const PasswordInput = ({
  label,
  name,
  value,
  errors,
  onChange,
  onBlur,
  autoFocus,
  className,
  includePasswordReset,
}: PasswordInputProps) => {
  const translations = useRecoilValue(translationsState);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box className={styles.passwordInputContainer}>
      <TextField
        label={label}
        name={name}
        value={value}
        onChange={(e) => onChange?.(name ?? '', e.target.value)}
        onBlur={() => onBlur?.(name)}
        helperText={
          errors && errors.length > 0 && <HelperText errors={errors} />
        }
        error={errors && errors.length > 0}
        autoFocus={autoFocus}
        className={cx(styles.passwordInput, className)}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" className={styles.endAdornment}>
              <MuiIconButton
                onClick={() => setShowPassword((prev) => !prev)}
                size="small"
              >
                {showPassword ? (
                  <VisibilityIcon fontSize="small" />
                ) : (
                  <VisibilityOffIcon fontSize="small" />
                )}
              </MuiIconButton>
            </InputAdornment>
          ),
        }}
      />
      {includePasswordReset && (
        <Link to={routes.auth.passwordReset} className={styles.link}>
          {translate(translations, 'PASSWORD_INPUT.FORGOT_PASSWORD')}
        </Link>
      )}
    </Box>
  );
};

export default PasswordInput;
