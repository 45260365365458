import React, { ReactNode } from 'react';
import { Button as MuiButton } from '@mui/material';
import styles from './Button.module.scss';
import cx from 'classnames';

export enum ButtonVariant {
  'primary' = 'primary',
  'outline' = 'outline',
  'secondary' = 'secondary',
  'contained' = 'contained',
}

type ButtonProps = {
  children: ReactNode;
  variant?: ButtonVariant;
  className?: string;
  wrapperClassName?: string;
  onClick?: () => void;
};

export const Button = ({
  children,
  variant = ButtonVariant.primary,
  className,
  wrapperClassName,
  onClick,
}: ButtonProps) => {
  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      <MuiButton
        className={cx(styles.buttonContainer, styles[variant], className)}
        onClick={onClick}
        disableRipple={variant === ButtonVariant.contained}
      >
        {children}
      </MuiButton>
    </div>
  );
};

export default Button;
