import React, { useMemo, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './TopNavigation.module.scss';
import logo from '../../../../assets/logo.svg';
import garageIcon from '../../../../assets/icons/garage.svg';
import userIcon from '../../../../assets/icons/user.svg';
import TopNavigationIconItem from './TopNavigationIconItem/TopNavigationIconItem';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { Link } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import ItemsDropdown from '../../../components/ItemsDropdown/ItemsDropdown';
import SearchInput from '../../../components/SearchInput/SearchInput';
import CartInfo from './CartInfo/CartInfo';
import carIcon from '../../../../assets/icons/car.svg';

import truckIcon from '../../../../assets/icons/truck.svg';
import motorcycleIcon from '../../../../assets/icons/motorcycle.svg';
import tyresIcon from '../../../../assets/icons/tyres.svg';
import toolsIcon from '../../../../assets/icons/tools.svg';
import carAccessoriesIcon from '../../../../assets/icons/accessories.svg';
import engineOilIcon from '../../../../assets/icons/engine-oil.svg';
import filtersIcon from '../../../../assets/icons/filters.svg';
import brakesIcon from '../../../../assets/icons/brakes.svg';
import eMobilityIcon from '../../../../assets/icons/e-mobility.svg';
import { ReactSVG } from 'react-svg';
import { useWindowWidth } from '@react-hook/window-size';
import MobileDrawer from './MobileDrawer/MobileDrawer';
import LoginModal from '../../../components/LoginModal/LoginModal';
import MyGarageMenu from '../../../components/MyGarageMenu/MyGarageMenu';

const fakePartTypes = [
  {
    id: 1,
    icon: truckIcon,
    title: 'Truck parts',
  },
  {
    id: 2,
    icon: motorcycleIcon,
    title: 'Motorcycle parts',
  },
  {
    id: 3,
    icon: tyresIcon,
    title: 'Tyres',
  },
  {
    id: 4,
    icon: toolsIcon,
    title: 'Tools',
  },
  {
    id: 5,
    icon: carAccessoriesIcon,
    title: 'Car accessories',
  },
  {
    id: 6,
    icon: engineOilIcon,
    title: 'Engine oil',
  },
  {
    id: 7,
    icon: filtersIcon,
    title: 'Filters',
  },
  {
    id: 8,
    icon: brakesIcon,
    title: 'Brakes',
  },
  {
    id: 9,
    icon: eMobilityIcon,
    title: 'E-mobility',
  },
];

const MOBILE_BREAKPOINT = 1200;

export const TopNavigation = () => {
  const translations = useRecoilValue(translationsState);
  const width = useWindowWidth();
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isGarageMenu, setIsGarageMenu] = useState(true);
  const garageTitleContainerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  return (
    <>
      <Box className={styles.topNavigationContainer}>
        <Box className={styles.innerContainer}>
          <Box className={styles.upperPart}>
            {isMobile && <MobileDrawer />}
            <Link to={routes.homepage}>
              <img src={logo} />
            </Link>
            {!isMobile && (
              <Box className={styles.upperIconItems}>
                <Box ref={garageTitleContainerRef}>
                  <TopNavigationIconItem
                    icon={garageIcon}
                    title={translate(
                      translations,
                      'TOP_NAVIGATION.MY_GARAGE_TITLE',
                    )}
                    subtitle={translate(
                      translations,
                      'TOP_NAVIGATION.MY_GARAGE_SUBTITLE',
                    )}
                    onClick={() => setIsGarageMenu(true)}
                  />
                  <MyGarageMenu
                    isOpen={isGarageMenu}
                    onClose={() => setIsGarageMenu(false)}
                    anchorEl={garageTitleContainerRef.current}
                  />
                </Box>
                <TopNavigationIconItem
                  icon={userIcon}
                  title={translate(
                    translations,
                    'TOP_NAVIGATION.MY_PARTS_TITLE',
                  )}
                  subtitle={translate(
                    translations,
                    'TOP_NAVIGATION.MY_PARTS_SUBTITLE',
                  )}
                  onClick={() => setIsLoginModal(true)}
                />
              </Box>
            )}
            {isMobile && <CartInfo />}
          </Box>
          <Box className={styles.searchItemsContainer}>
            {!isMobile && <ItemsDropdown title={'Car parts'} icon={carIcon} />}
            <SearchInput />
            {!isMobile && <CartInfo />}
          </Box>
          {!isMobile && (
            <Box className={styles.partTypesContainer}>
              {fakePartTypes.map((type) => (
                <Box key={type.id} className={styles.type}>
                  <ReactSVG className={styles.typeIcon} src={type.icon} />
                  <Typography className={styles.typeTitle}>
                    {type.title}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <LoginModal
        isOpen={isLoginModal}
        onClose={() => setIsLoginModal(false)}
      />
    </>
  );
};

export default TopNavigation;
