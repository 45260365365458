import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import BasketNavigation from '../../components/BasketNavigation/BasketNavigation';
import Subscribe from '../../components/Subscribe/Subscribe';

export const BasketLayout = () => {
  return (
    <>
      <BasketNavigation />
      <Outlet />
      <Subscribe />
      <Footer />
    </>
  );
};

export default BasketLayout;
