import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Subscribe.module.scss';
import Button, { ButtonVariant } from '../../common/Button/Button';
import checkIcon from '../../../assets/icons/check.svg';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';

export const Subscribe = () => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Box className={styles.subscribeContainer}>
      <Box className={styles.inner}>
        <Box className={styles.textContainer}>
          <Typography
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: `<strong>Subscribe</strong> and save up <strong>to 20%</strong>`,
            }}
          />
          <Typography className={styles.subtitle}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et
            mattis dolor, finibus sagittis dui.
          </Typography>
        </Box>
        <Box className={styles.actionContainer}>
          <Box className={styles.inputContainer}>
            <input placeholder={'Your e-mail'} />
            <Button className={styles.button} variant={ButtonVariant.primary}>
              Subscribe
            </Button>
          </Box>
          <Box className={styles.agreementContainer}>
            <Box
              className={cx(styles.checkbox, { [styles.checked]: isChecked })}
              onClick={() => setIsChecked((prev) => !prev)}
            >
              {isChecked && <img src={checkIcon} />}
            </Box>
            <Typography className={styles.explanation}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam et
              mattis dolor, finibus sagittis dui.
              <Link to={routes.homepage}>Read more</Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Subscribe;
