import React from 'react';
import { Link } from 'react-router-dom';
import styles from './FooterItem.module.scss';

export type FooterItemProps = {
  label: string;
  link: string;
  icon?: string;
};

export const FooterItem = ({ label, link, icon }: FooterItemProps) => {
  return (
    <Link className={styles.item} to={link}>
      {icon && <img src={icon} className={styles.icon} />}
      {label}
    </Link>
  );
};

export default FooterItem;
