import React from 'react';
import Section from '../Section/Section';
import ManufacturerCard from './ManufacturerCard/ManufacturerCard';
import styles from './Manufacturers.module.scss';

export const Manufacturers = () => {
  return (
    <Section
      title={
        'Get great deals on car spare parts from the best manufacturers in our online shop'
      }
      className={styles.cardsContainer}
      titleClassName={styles.title}
    >
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
      <ManufacturerCard title={'LOGO'} />
    </Section>
  );
};

export default Manufacturers;
