import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import styles from './BasketNavigation.module.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../../config/routes/routes';
import leftActionIcon from '../../../assets/icons/left-action.svg';
import secureIcon from '../../../assets/icons/secure.svg';
import basketIcon from '../../../assets/icons/basket.svg';
import loginIcon from '../../../assets/icons/login.svg';
import addressIcon from '../../../assets/icons/address.svg';
import paymentIcon from '../../../assets/icons/payment.svg';
import confirmationIcon from '../../../assets/icons/confirmation.svg';
import logo from '../../../assets/logo.svg';
import { ReactSVG } from 'react-svg';

export const BasketNavigation = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box className={styles.basketNavigationContainer}>
      <Box className={styles.basketNavigation}>
        <Box className={styles.controlsContainer}>
          <Link to={routes.homepage} className={styles.backButton}>
            <img src={leftActionIcon} />
            <Typography className={styles.backLabel}>Back to shop</Typography>
          </Link>
          <Link to={routes.homepage} className={styles.logo}>
            <img src={logo} />
          </Link>
          <Box className={styles.secureLogin}>
            <img src={secureIcon} />
            <Typography className={styles.secureLabel}>
              Saugus prisijungimas
            </Typography>
          </Box>
        </Box>
        <Box className={styles.tabItemsContainer}>
          <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
            <Tab
              className={styles.tabItem}
              label="Krepšelis"
              icon={<ReactSVG src={basketIcon} />}
            />
            <Tab
              className={styles.tabItem}
              label="Prisijungti"
              icon={<ReactSVG src={loginIcon} />}
            />
            <Tab
              className={styles.tabItem}
              label="Pristatymo adresas"
              icon={<ReactSVG src={addressIcon} />}
            />
            <Tab
              className={styles.tabItem}
              label="Apmokėjimas"
              icon={<ReactSVG src={paymentIcon} />}
            />
            <Tab
              className={styles.tabItem}
              label="Patvirtinimas"
              icon={<ReactSVG src={confirmationIcon} />}
            />
          </Tabs>
        </Box>
      </Box>
    </Box>
  );
};

export default BasketNavigation;
