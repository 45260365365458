import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './Community.module.scss';
import facebookIcon from '../../../assets/socials/facebook.svg';
import youtubeIcon from '../../../assets/socials/youtube.svg';
import instagramIcon from '../../../assets/socials/instagram.svg';
import linkedinIcon from '../../../assets/socials/linkedin.svg';
import tiktokIcon from '../../../assets/socials/tik-tok.svg';
import SocialCard from './SocialCard/SocialCard';

export const Community = () => {
  return (
    <Box className={styles.communityContainer}>
      <Box className={styles.innerContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>
            Be part of our community
          </Typography>
          <Typography className={styles.subtitle}>
            Let’s become friends on Instagram, youtube or any other social
            platform and be first one who learns about tips and discounts
          </Typography>
        </Box>
        <Box className={styles.cardsContainer}>
          <SocialCard image={facebookIcon} name={'part.evo'} />
          <SocialCard image={youtubeIcon} name={'part.evo'} />
          <SocialCard image={instagramIcon} name={'part.evo'} />
          <SocialCard image={linkedinIcon} name={'part.evo'} />
          <SocialCard image={tiktokIcon} name={'part.evo'} />
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
