import { Box, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import styles from './ProfileSidebar.module.scss';
import { Link } from 'react-router-dom';
import { routes } from '../../../../config/routes/routes';
import Button from '../../../common/Button/Button';
import { ReactSVG } from 'react-svg';
import garageIcon from '../../../../assets/icons/garage.svg';
import addressIcon from '../../../../assets/icons/address.svg';
import orderIcon from '../../../../assets/icons/confirmation.svg';
import bankIcon from '../../../../assets/icons/bank.svg';
import settingsIcon from '../../../../assets/icons/settings.svg';
import favoriteIcon from '../../../../assets/icons/favorite-filled-small.svg';
import signoutIcon from '../../../../assets/icons/signout.svg';
import moreIcon from '../../../../assets/icons/more.svg';
import { useWindowWidth } from '@react-hook/window-size';

const MOBILE_BREAKPOINT = 970;

const ProfileSidebar = () => {
  const width = useWindowWidth();
  const [isMore, setIsMore] = useState(false);

  const isMobile = useMemo(() => width < MOBILE_BREAKPOINT, [width]);

  return (
    <Box className={styles.sidebar}>
      <Box className={styles.client}>
        <img
          src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
          className={styles.image}
        />
        <Box className={styles.info}>
          <Box className={styles.name}>
            <Typography>
              Vardenis Pavardenis if needed super long one can take up to few
              rows
            </Typography>
          </Box>
          <Box className={styles.clientNumber}>
            <Typography>Kliento numeris</Typography>
            <Typography>123456789</Typography>
          </Box>
          <Box className={styles.balance}>
            <Typography>Jūsų sąskaitoje</Typography>
            <Typography>9999,99 €</Typography>
          </Box>
        </Box>
      </Box>

      <Box className={styles.navigation}>
        <Link to={routes.profile.vehicles}>
          <ReactSVG src={garageIcon} /> Mano Automobiliai
        </Link>
        <Link to={routes.profile.orders}>
          <ReactSVG src={orderIcon} /> Mano užsakymai
        </Link>
        {!isMobile && (
          <>
            <Link to={routes.profile.address}>
              <ReactSVG src={addressIcon} /> Mano adresai
            </Link>
            <Link to={routes.profile.bankDetails}>
              <ReactSVG src={bankIcon} /> Banko duomenys
            </Link>
            <Link to={routes.profile.settings}>
              <ReactSVG src={settingsIcon} /> Nustatymai
            </Link>
          </>
        )}
        <Link to={routes.profile.favorites}>
          <ReactSVG src={favoriteIcon} /> Pageidavimų sąrašas
        </Link>
        {!isMobile ? (
          <button className={styles.signoutBtn}>
            <ReactSVG src={signoutIcon} /> Atsijungti
          </button>
        ) : (
          <button className={styles.moreBtn} onClick={() => setIsMore(true)}>
            <ReactSVG src={moreIcon} /> Daugiau
            {isMore && (
              <Box className={styles.moreMenu}>
                <Link to={routes.profile.address}>
                  <ReactSVG src={addressIcon} /> Mano adresai
                </Link>
                <Link to={routes.profile.bankDetails}>
                  <ReactSVG src={bankIcon} /> Banko duomenys
                </Link>
                <Link to={routes.profile.settings}>
                  <ReactSVG src={settingsIcon} /> Nustatymai
                </Link>
                <Button className={styles.signoutBtn}>
                  <ReactSVG src={signoutIcon} /> Atsijungti
                </Button>
              </Box>
            )}
          </button>
        )}
      </Box>
    </Box>
  );
};

export default ProfileSidebar;
